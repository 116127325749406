import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";



export const base = css`
flex: 1 1 auto;
padding: 20px 0;
display: flex;
flex-direction: column;
background-color: ${COLORS.paleGrey};
`;


export const centered = css`
justify-content: center;
`;


export const hideFooter = css`
#footer {
  display: none !important;
}
`;
