import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";



export const base = css`
font-weight: ${VARS.font.bold};
align-items: center;
margin-top: 10px;
svg {
  margin-right: 10px;
}
`;



export const headers = {
  h1: css`
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 20px;
  `,

  h2: css`
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 16px;
  `,

  h3: css`
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 14px;
  `,

  h4: css`
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
  `,

  h5: css`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  `,

  h6: css`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  `,

  p: css`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    font-weight: ${VARS.font.normal};
    color: ${COLORS.battleshipGrey};
  `

};


export const centered = css`
text-align: center;
`;
