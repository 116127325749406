import _ from "lodash";

import { css } from "@emotion/react";



import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

const transition = `transition: all ${VARS.animation.duration} ${VARS.animation.timing};`;

const colorsWillBeUsedInTheme = {
  primary: COLORS.azure,
  gray: COLORS.slateGrey,
  orange: COLORS.primaryOrange,
  white: "white"
};


export const button = css`
  background: none;
  background-color: transparent;
  border: solid 1px transparent;
  border-color: ${COLORS.primary};
  border-radius: 2px;
  box-shadow: none;
  color: ${COLORS.primary};
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: ${VARS.font.semiBold};
  font-style: normal;
  height: 56px;
  line-height: 36px;
  margin: 0;
  outline: 0;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  user-select: none;
  vertical-align: baseline;
  will-change: '';
  display: flex;
  align-items: center;
  justify-content: center;
  ${transition};
  svg {
    object-fit: contain;
    object-position: center center;
    max-width: 24px;
    max-height: 24px;
  }
  svg,
  path {
    fill: ${COLORS.primary};
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: 0 0 0 1px ${COLORS.primary};
  }
  &:active {
    opacity: 0.8;
  }
  &:hover {
    text-decoration: none;
  }
`;



export const unstyled = css`
background-color: transparent;
margin: 0;
padding: 0;
font-size: inherit;
border: none;
box-shadow: none;
outline: none;
color: inherit;
cursor: pointer;
&[disabled] {
  cursor: not-allowed;
}
`;


export const content = css`
margin: 0 3px;
display: flex;
`;

export const sizes = {
  small: css`
    font-size: 16px;
    height: 48px;
  `,
  twoLine: css`
    /* padding-left: 8px !important;
    padding-right: 8px !important; */
    .btn-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      line-height: 1.3;
      flex: 0 1 auto !important;
      text-align: left !important;
      font-weight: 500;
      font-size: 16px;
      span:nth-of-type(2) {
        font-size: 12px;
      }
    }
  `,
  mini: css`
    height: 32px;
    line-height: 18px;
    font-size: 12px;
  `
};


export const withIcon = {
  left: css`
  > img,
  > i,
  > svg  {
    margin-right: 6px;
  }
  `,
  right: css`
  > img,
  > i,
  > svg  {
    margin-left: 6px;
  }
  `
};


export const loading = css`
  &[disabled] {
    opacity: 1 !important;
  }
    cursor: progress !important;
`;

export const fluid = css`
width: 100%;
`;


export const themes = _.mapValues(colorsWillBeUsedInTheme, color =>
  css`
  background-color: ${color};
  border-color: ${color};
  span,
  &,
  .btn-content span:nth-of-type(2) {
    color: white;
  }
  svg,
  path {
    fill: white;
  }
  &:hover {
    background-color: ${HELPERS.color.lightenDarken(color, 20)};
    border-color: ${HELPERS.color.lightenDarken(color, 20)};
  }
  &:focus {
    box-shadow: 0 0 0 1px ${color};
  }
  `
);

export const outlines = _.mapValues(colorsWillBeUsedInTheme, color =>
  css`
  background-color: transparent;
  background-color: white;
  border-color: ${color};
  color: ${color};
  .btn-content span:nth-of-type(2) {
    color: ${HELPERS.color.lightenDarken(color, 20)};
  }
  svg,
  path {
    fill: ${color};
  }
  &:hover {
    background-color: ${color};
    span,
    &,
    .btn-content span:nth-of-type(2) {
      color: white;
    }
    svg,
    path {
      fill: white;
    }
  }
  &:focus {
    box-shadow: 0 0 0 1px ${color};
  }
  `
);
