

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { FormatNumber } from "kit/ui";

import COUNTRY from "../../../constants/COUNTRY";

const {
  currency: {
    symbol,
    position,
    isSeparate
  },
  number: {
    decimal,
    thousand,
    precision
  }
} = COUNTRY;


const formatValueWithCurrencySymbol = value => {
  const currencyArray = (_.isNil(value) || _.isNaN(value)) ? [] : [
    symbol,
    isSeparate ? " " : "",
    FormatNumber(value, precision, thousand, decimal)
  ];
  const arr = position === "left" ? currencyArray : _.reverse(currencyArray);
  return arr.join("");
};
const returnCurrencyObject = value => {
  if (_.isNil(value) || _.isNaN(value)) return {};
  const intAndDecimalArr = String(value).split(".");
  const int = intAndDecimalArr[0] || "0";
  const deci = !!intAndDecimalArr[1] && intAndDecimalArr[1].length > 1 ? intAndDecimalArr[1] :
    !!intAndDecimalArr[1] && intAndDecimalArr[1].length === 1 ? `${intAndDecimalArr[1]}0` :
      "00";
  return {
    int,
    decimal: deci,
    decimalSeperator: decimal,
    symbol
  };
};


class Currency extends PureComponent {

  static SYMBOL = symbol;
  static SEPERATOR = thousand;
  static raw = formatValueWithCurrencySymbol;
  static returnCurrencyObject = returnCurrencyObject;

  render() {
    const { value } = this.props;
    const txt = formatValueWithCurrencySymbol(value);
    return txt;
  }
}

Currency.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

Currency.contextTypes = {
  country: PropTypes.object.isRequired
};

export default Currency;
