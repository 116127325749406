// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import generateGridMediaStyles from "./generateGridMediaStyles";

import { base } from "./Container.styles";


const DEBUG = import.meta.env.DEV && false;


type ComponentPropTypes = {
  as: string;
  fluid: boolean;
  style: Object;
  children: React.Node;
}

class Container extends Component<ComponentPropTypes> {

  render() {
    const {
      as = "div",
      fluid,
      children,
      style,
      ...otherProps
    } = this.props;

    const Tag = as;

    return <Tag
      css={[
        DEBUG && { boxShadow: "0 0 2px 2px green" },
        base,
        fluid && {},
        style
      ]}
      {...otherProps}>

      {children}

    </Tag>;
  }
}


Container.propTypes = {
  style: PropTypes.object,
  as: PropTypes.string,
  fluid: PropTypes.bool,
  children: PropTypes.node
};

export default Container;
