// @ts-nocheck

import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";


type ComponentPropsType = {
  blurred: boolean;
  whenVisible: boolean;
  threshold: number;
  targetSrc: string;
  children: React.Node;
}
type ComponentStateType = {
  isLoading: boolean;
  isLoaded: boolean;
  src?: string;
};

const styles = {
  blurred: {
    filter: "blur(4px)"
  }
};

class ImageLazyLoad extends PureComponent<ComponentPropsType, ComponentStateType> {

  constructor(props) {
    super(props);
    this.imageObserver = null;
    this.imageRef = React.createRef();
  }

  static defaultProps = {
    threshold: 0.1,
    whenVisible: true,
    blurred: true
  };


  state = {
    isLoading: false,
    isLoaded: false,
    src: null
  };

  componentDidMount() {
    if (this.props.whenVisible) {
      this.attachObserver();
    }
    else {
      this.handleLoadImage();
    }
  }

  attachObserver = () => {
    try {
      const elem = this.imageRef.current;
      this.imageObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.imageObserver.unobserve(elem);
            this.handleLoadImage();
          }
        });
      }, { threshold: this.props.threshold });
      this.imageObserver.observe(elem);
    }
    catch (err) {
      ErrorLoggerService("error", "ImageLazyLoad", err);
      this.handleLoadImage();
    }
  };

  handleLoadImage = () => {
    this.setState({
      isLoading: true
    });
    const imageToBeLoaded = new Image();
    imageToBeLoaded.src = this.props.targetSrc;
    imageToBeLoaded.alt = this.props.alt;
    imageToBeLoaded.onload = () => {
      this.setState({
        isLoading: false,
        isLoaded: true,
        src: this.props.targetSrc,
        alt: this.props.alt
      });
    };
  };

  render() {
    const {
      blurred = true,
      children: ChildElement
    } = this.props;

    const {
      isLoading,
      isLoaded,
      src,
      alt
    } = this.state;

    const elemProps = Object.assign(
      {},
      ChildElement.props,
      {
        ref: this.imageRef,
        css: [
          ChildElement.props.css,
          ChildElement.props.style,
          isLoading && blurred && styles.blurred
        ]
      },
      isLoaded && {
        src,
        alt
      }
    );
    return React.createElement(ChildElement.type, elemProps);
  }

}

ImageLazyLoad.propTypes = {
  blurred: PropTypes.bool,
  targetSrc: PropTypes.string,
  alt: PropTypes.string,
  threshold: PropTypes.number,
  whenVisible: PropTypes.bool,
  children: PropTypes.element.isRequired
};

export default ImageLazyLoad;
