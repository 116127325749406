// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";


import generateGridMediaStyles from "./generateGridMediaStyles";


const DEBUG = import.meta.env.DEV && false;



type JustifyContentTypes = "start" | "center" | "space-between" | "space-around" | "space-evenly";
type AlignItemsTypes = "stretch" | "center" | "start" | "end";


type ComponentPropTypes = {
  as: string;
  wrap: string | string[];
  reverse: boolean | boolean[];
  width: number | number[];
  space: number | number[];
  justifyContent: JustifyContentTypes | JustifyContentTypes[];
  alignItems: AlignItemsTypes | AlignItemsTypes[];
}


class Row extends Component<ComponentPropTypes> {

  returnAlignItems = (val) => ({
    alignItems: val
  });

  returnJustifyContent = (val) => ({
    justifyContent: val
  });

  returnWrap = (val) => ({
    flexWrap: val ? "wrap" : "nowrap"
  });

  returnReverse = (reverse) => ({
    flexDirection: reverse ? "row-reverse" : "row"
  });

  returnColumnSpaces = (val) => ({
    marginLeft: `-${val}px !important`,
    marginRight: `-${val}px !important`,
    "> *": {
      paddingRight: `${val}px !important`,
      paddingLeft: `${val}px !important`
    }
  });

  render() {
    const {
      children,
      direction = "row",
      compact = false,
      as = "div",
      justifyContent,
      wrap = false,
      alignItems,
      space = 0,
      reverse = false,
      ...otherProps
    } = this.props;

    const Tag = as;

    return <Tag
      css={[
        DEBUG && { boxShadow: "0 0 2px 2px blue" },
        {
          display: "flex"
        },
        {
          flexBasis: `${compact ? "auto" : "100%"}`
        },
        generateGridMediaStyles(reverse, this.returnReverse),
        generateGridMediaStyles(wrap, this.returnWrap),
        generateGridMediaStyles(alignItems, this.returnAlignItems),
        generateGridMediaStyles(justifyContent, this.returnJustifyContent),
        generateGridMediaStyles(space, this.returnColumnSpaces)
      ]}
      {...otherProps}>

      {children}

    </Tag>;
  }
}


Row.propTypes = {
  direction: PropTypes.string,
  compact: PropTypes.bool,
  as: PropTypes.string,
  reverse: PropTypes.oneOfType([ PropTypes.bool, PropTypes.array ]),
  start: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  center: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  end: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  top: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  middle: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  bottom: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  around: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  between: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  first: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  last: PropTypes.oneOf([ "xs", "sm", "md", "lg" ]),
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.node
};

export default Row;
