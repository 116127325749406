// @ts-nocheck
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import * as styles from "./ProgressBar.styles";


type ComponentPropsType = {
  duration: number;
  color: string;
}


class ProgressBar extends Component<ComponentPropsType> {

  render() {
    const { duration, color } = this.props;
    return (
      <div css={styles.base}>
        <div css={[
          styles.inner,
          {
            animationDuration: `${duration}s`
          },
          color && {
            backgroundColor: color
          }
        ]} />
      </div>
    );
  }
}



ProgressBar.propTypes = {
  duration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  color: PropTypes.string
};

export default ProgressBar;
