// @ts-nocheck

import React, { Component } from "react";
import PropTypes from "prop-types";

import { Global } from "@emotion/react";

import * as styles from "./PageWrapper.styles";


type ComponentPropsType = {
  centered: boolean;
  children: React.ReactNode;
  hideFooter: Boolean;
  style: Object;
}



class PageWrapper extends Component<ComponentPropsType> {
  render() {
    const {
      centered,
      children,
      hideFooter = false,
      style
    } = this.props;
    return (
      <>
        {hideFooter && (
          <Global styles={styles.hideFooter} />
        )}
        <div css={[
          styles.base,
          centered && styles.centered,
          style
        ]}>
          {children}
        </div>
      </>
    );
  }
}


PageWrapper.propTypes = {
  hideFooter: PropTypes.bool,
  centered: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node
};



export default PageWrapper;
