// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment, type ButtonHTMLAttributes } from "react";

import ButtonLoader from "./Button.Loader";

import * as styles from "./Button.styles";

type ThemesType = "gray" | "primary" | "alizarin" | "amethyst" | "asbestos" | "butturcup" | "carrot" | "white" | "azure";

type ComponentPropsType = {
  iconPosition: "left" | "right";
  as: String | React.ReactNode;
  content: String | React.ReactNode;
  theme: ThemesType;
  icon: String | React.ReactNode;
  iconPosition: "left" | "right";
  fluid: boolean;
  unstyled: boolean;
  loading: boolean;
  size: "small" | "mini";
  contentStyles: Object;
  outline: boolean;
} & ButtonHTMLAttributes;


class Button extends PureComponent<ComponentPropsType> {

  constructor(props) {
    super(props);
  }

  state = {};

  render() {
    const {
      as = "button",
      content,
      children,
      fluid,
      loading = false,
      contentStyles,
      theme = "primary",
      size,
      unstyled = false,
      style,
      outline = false,
      icon,
      iconPosition = "left",
      ...otherProps
    } = this.props;

    const stylesMerged = unstyled ? [ styles.unstyled, style ] : [
      styles.button,
      outline ? styles.outlines[theme] : styles.themes[theme],
      loading && styles.loading,
      fluid && styles.fluid,
      !!size && styles.sizes[size],
      !!icon && styles.withIcon[iconPosition],
      style
    ].filter(Boolean);

    const Tag = as;

    return (
      <Tag
        type="button"
        css={stylesMerged}
        {...otherProps}>
        {iconPosition === "left" && icon}

        <span
          className="btn-content"
          css={[
            styles.content,
            contentStyles
          ]}>
          {children || content}
        </span>

        {iconPosition === "right" && icon}
        <ButtonLoader loading={loading} />
      </Tag>
    );
  }
}

Button.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  icon: PropTypes.node,
  fluid: PropTypes.bool,
  loading: PropTypes.bool,
  unstyled: PropTypes.bool,
  contentStyles: PropTypes.object,
  theme: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  outline: PropTypes.bool,
  iconPosition: PropTypes.oneOf([ "left", "right" ])
};

export default Button;
