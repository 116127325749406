import { css, keyframes } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


const linear = keyframes`
0% {
  width:0%
}
100% {
  width:100%
}
`;


export const base = css`
  position: relative;
  width: 100%;
  border-radius: 2px;
  height: 4px;
  overflow: hidden;
  background-color: ${COLORS.paleGrey};
`;

export const inner = css`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: gray;
  animation-iteration-count: initial;
  animation-timing-function: linear;
  animation-name: ${linear};
`;



