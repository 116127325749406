// @ts-nocheck

import React, { Component } from "react";
import PropTypes from "prop-types";

import { Global } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import * as styles  from "./Title.styles";

type AsType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type ColorType = "blue" | "black";

type ComponentPropsType = {
  as: AsType;
  color: "black" | "blue" | "gray";
  centered: boolean;
  children: React.ReactNode;
  style: Object;
}

const COLOR_MAPS = {
  black: "#000",
  blue: COLORS.primary,
  gray: COLORS.battleshipGrey
};

class Title extends Component<ComponentPropsType> {
  render() {
    const {
      children,
      centered,
      color = "black",
      as = "p",
      tag = as,
      style,
      ...restProps
    } = this.props;
    const Tag = tag;
    return (
      <Tag
        css={[
          styles.base,
          styles.headers[as],
          centered && styles.centered,
          {
            color: COLOR_MAPS[color]
          },
          style
        ]}
        {...restProps}>
        {children}
      </Tag>
    );
  }
}


Title.propTypes = {
  tag: PropTypes.string,
  centered: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node
};



export default Title;
