// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import * as styles from "./CountDownTimer.styles";


type ComponentPropsType = {
  duration: number;
  onComplete: Function;
}


class CountDownTimer extends Component<ComponentPropsType> {

  constructor(props) {
    super(props);
    this.state = {
      remaining: this.props.duration
    };
    this.interval = null;
  }

  UNSAFE_componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidMount() {
    this.interval = setInterval(this.reduceTime, 1000);
  }

  reduceTime = () => {
    const newRemaining = this.state.remaining - 1;
    this.setState({
      remaining: newRemaining
    });
    if (newRemaining === 0) {
      _.invoke(this.props, "onComplete");
      clearInterval(this.interval);
    }
  };


  render() {
    const { remaining } = this.state;
    return (
      <>
        {_.padStart(Math.floor(remaining / 60), 2, "0")}:{_.padStart(remaining % 60, 2, "0")}
      </>
    );
  }
}



CountDownTimer.propTypes = {
  duration: PropTypes.number,
  onComplete: PropTypes.func
};

export default CountDownTimer;
