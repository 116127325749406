// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import generateGridMediaStyles from "./generateGridMediaStyles";

const DEBUG = import.meta.env.DEV && false;


type ComponentPropTypes = {
  as: string;
  width: number | number[];
}


class Col extends Component<ComponentPropTypes> {

  returnFlexBasis = (val) => ({
    flexBasis: `${Number(val * 100).toFixed(2)}%`,
    maxWidth: `${Number(val * 100).toFixed(2)}%`
  });

  render() {

    const {
      children,
      as = "div",
      width,
      ...otherProps
    } = this.props;

    const Tag = as;

    return <Tag
      css={[
        DEBUG && {
          background: "yellow",
          boxShadow: "0 0 1px 1px blue"
        },
        {
          flexGrow: 0,
          flexShrink: 1
        },
        generateGridMediaStyles(width, this.returnFlexBasis)
      ]}
      {...otherProps}>

      {children}

    </Tag>;
  }
}


Col.propTypes = {
  as: PropTypes.string,
  // width: PropTypes.oneOfType([
  //   PropTypes.arrayOf([
  //     PropTypes.number
  //   ]),
  //   PropTypes.number
  // ]),
  width: PropTypes.any,
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.node
};

export default Col;
