// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, Fragment, PureComponent } from "react";


import DateService from "../../../core/DateService";


const FORMATS = {
  default: "DD/MM/YYYY",
  short: "DD/MM/YYYY HH:mm",
  mid: "DD MMM YYYY HH:mm",
  long: "D MMMM YYYY - HH:mm:ss",
  dateOnly: "DD/MM/YYYY",
  timeOnly: "HH:mm:ss"
};

type ComponentPropsType = {
  date: string | Object;
  parseFormat: string | void;
  format: string | void | "short" | "mid" | "long" | "dateOnly" | "timeOnly";
};

class DateFormat extends PureComponent<ComponentPropsType> {

  parse = (dateStringOrObject: Object | string, parseFormat?: string): Object => {
    const dateObj = DateService.parse(dateStringOrObject, parseFormat);
    return dateObj;
  };

  isValid = (dateStringOrObject: Object | string, parseFormat?: string): Object => {
    return !!dateStringOrObject &&
    DateService.isValid(dateStringOrObject, parseFormat);
  };

  format = (dateObject: Object | string, format?: string, parseFormat?: string): string => {
    const dateObj = this.parse(dateObject, parseFormat);
    const targetFormat = DateService.getOutputFormat(format);
    return DateService.format(dateObj, targetFormat);
  };

  render() {
    const {
      date,
      format = FORMATS.default,
      parseFormat,
      ...otherProps
    } = this.props;
    const isDateValid = this.isValid(date, parseFormat);
    return (
      isDateValid ?
        <>
          {this.format(date, format, parseFormat)}
        </> :
        <>-</>
    );
  }
}

DateFormat.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  format: PropTypes.string,
  parseFormat: PropTypes.string
};

export default DateFormat;
