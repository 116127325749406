// @ts-nocheck

import _ from "lodash";

import dayjs from "dayjs";
import toObject from "dayjs/plugin/toObject";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(toObject);
dayjs.extend(customParseFormat);
dayjs.extend(utc);


export class DateConstructor {

  static FORMATS = {
    default: "DD/MM/YYYY HH:mm:ss",
    short: "DD/MM/YYYY HH:mm",
    mid: "DD MMM YYYY HH:mm",
    long: "D MMMM YYYY - HH:mm:ss",
    dateOnly: "DD/MM/YYYY",
    timeOnly: "HH:mm:ss",
    LT: "h:mm A", // 8:02 PM
    LTS: "h:mm:ss A", // 8:02:18 PM
    L: "MM/DD/YYYY", // 08/16/2018
    LL: "MMMM D, YYYY", // August 16, 2018
    LLL: "MMMM D, YYYY h:mm A", // August 16, 2018 8:02 PM
    LLLL: "dddd, MMMM D, YYYY h:mm A", // Thursday, August 16, 2018 8:02 PM
    l: "M/D/YYYY", // 8/16/2018
    ll: "MMM D, YYYY", // Aug 16, 2018
    lll: "MMM D, YYYY h:mm A", // Aug 16, 2018 8:02 PM
    llll: "ddd, MMM D, YYYY h:mm A", // Thu, Aug 16, 2018 8:02 PM
    ISO: "" // Thu, Aug 16, 2018 8:02 PM
  };

  setLocale(localeObjectOrString) {
    dayjs.locale(localeObjectOrString);
  }

  setTimeZone(timezone) {
    dayjs.tz.setDefault(timezone);
  }

  get lib() {
    return dayjs;
  }

  get FORMATS() {
    return DateConstructor.FORMATS;
  }

  getOutputFormat = (outputFormat) =>
    _.defaultTo(
      DateConstructor.FORMATS[outputFormat],
      _.defaultTo(outputFormat, DateConstructor.FORMATS.default)
    );

  parse = (dateStringOrObject, inputFormat) => {
    const dateObj = dayjs(dateStringOrObject, inputFormat).toDate();
    return dateObj;
  };

  isValid = (dateStringOrObject, inputFormat) => {
    return !!dateStringOrObject &&
      dayjs(dateStringOrObject, inputFormat).isValid();
  };

  format = (dateObject, outputFormat, inputFormat) => {
    const dateObj = this.parse(dateObject, inputFormat);
    const targetFormat = this.getOutputFormat(outputFormat);
    return dayjs(dateObj).format(targetFormat);
  };

  toObject = (dateStringOrObject, inputFormat) => {
    const dateObj = this.parse(dateStringOrObject, inputFormat);
    return dayjs(dateObj).toObject();
  };

  fromNow = (dateStringOrObject, inputFormat, withoutSuffix = false) => {
    const dateObj = this.parse(dateStringOrObject, inputFormat);
    return dayjs(dateObj).fromNow(withoutSuffix);
  };

  add = (dateStringOrObject, unit, amount, outputFormat) => {
    const targetFormat = this.getOutputFormat(outputFormat);
    return dayjs(dateStringOrObject).add(unit, amount)
      .format(targetFormat);
  };

  unixFormat = (unixTimeStamp, outputFormat) => {
    const targetFormat = this.getOutputFormat(outputFormat);
    return dayjs.unix(unixTimeStamp).format(targetFormat);
  };
}


export default new DateConstructor();
