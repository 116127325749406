import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";



export const base = css`
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  ${HELPERS.media.smallerThan.sm} {
    ${HELPERS.media.largerThan.custom(BREAKPOINTS.mobileMaxFluidWidth)} {
      width: ${BREAKPOINTS.mobileMaxFluidWidth}px;
    }
  }
  ${HELPERS.media.largerThan.sm} {
    padding: 0 20px;
  }
  ${HELPERS.media.largerThan.lg} {
    max-width: ${VARS.maxContainerWidth}px;
  }
`;
