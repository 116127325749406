// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import accounting from "accounting";

export const FormatNumber = (value, precision, thousand, decimal) => {

  const resetValue = accounting.formatNumber(value, 2, thousand, decimal);

  const nonDecimal = _.startsWith(
    _.last(
      _.split(resetValue, decimal)),
    "00"
  );

  const validPrecision = nonDecimal ? 0 : precision;

  return (accounting.formatNumber(value, validPrecision, thousand, decimal));
};

export const Num = ({ value, precision }, context) => {
  return (
    <>
      {accounting.formatNumber(value, (precision ? precision : context.country.number.precision), context.country.number.thousand, context.country.number.decimal)}
    </>
  );
};


Num.propTypes = {
  precision: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
};

Num.contextTypes = {
  country: PropTypes.object.isRequired
};
