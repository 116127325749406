const VARS = {

  maxContainerWidth: 1240,

  headerHeight: 56,
  mobileMenuWidth: 400,

  mobileBreakpoint: 890,

  base: {
    fontSize: "14px",
    fontFamily: "Manrope",
    // fontFamily: "Nunito Sans",
    fontWeight: "500"
  },

  font: {
    regular: 400,
    medium: 500,
    bold: 600,
    extraBold: 800
  },

  animation: {
    duration: "150ms",
    timing: "ease-out"
  },

  form: {
    // successColor: "#06edc1",
    successColor: "#36cd77",
    // errorColor: "#ff7c7c",
    errorColor: "#f6463e"
  }

};


export default VARS;
