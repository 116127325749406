// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import autobind from "autobind-decorator";

import { Popup } from "semantic-ui-react";

import { Icon } from "kit/ui";

type ComponentPropsType = {
  value: any;
  position: "top center" | "top left" | "top right" | "bottom center" | "bottom left" | "bottom right" | "right center" | "left center";
  size: number;
}

const copyStr = str => {
  try {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
        document.getSelection().rangeCount > 0 ?
          document.getSelection().getRangeAt(0) :
          false;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }
  catch (err) {
    window["console"]["log"]("error", "Copy", err); // eslint-disable-line dot-notation
  }
};

const LABELS = {
  copy: "Copy",
  copied: "Copied!"
};

class Copy extends PureComponent<ComponentPropsType> {

  static copyStr = copyStr;

  constructor(props) {
    super(props);
    this.updateTextInterval = null;
  }

  state = {
    label: this.props.text || LABELS.copy
  };

  componentWillUnmount() {
    clearInterval(this.updateTextInterval);
  }

  copyText = (str) => {
    try {
      copyStr(str);
      this.setState({
        label: LABELS.copied
      });
      this.updateTextInterval = setTimeout(() => {
        this.setState({
          label: LABELS.copy
        });
      }, 2000);
    }
    catch (err) {
      window["console"]["error"]("Copy.js", err); // eslint-disable-line dot-notation
    }
  };

  handleClose = () => this.setState({
    label: this.props.text || LABELS.copy
  });

  handleCopy = (event) => {
    this.copyText(this.props.value);
  };

  render() {
    const {
      value = "No text",
      children,
      position = "top center",
      size = 14,
      ...otherProps
    } = this.props;
    return (
      <Popup
        size="small"
        // keepInViewPort
        onClose={this.handleClose}
        inverted
        style={{
          width: 80,
          textAlign: "center"
        }}
        closeOnTriggerClick={false}
        position={position}
        trigger={children ? children({ handleCopy: this.handleCopy }) : <Icon
          size={size}
          title={value}
          onClick={this.handleCopy}
          name="far copy" />}
        content={this.state.label}
        {...otherProps} />
    );
  }
}

Copy.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  text: PropTypes.string,
  children: PropTypes.any,
  otherProps: PropTypes.object,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  position: PropTypes.string
};

export default Copy;
