import SOURCE from "../../tfs-build-params.json";

const ENV = {
  NODE_ENV: import.meta.env.VITE_NODE_ENV,
  TARGET: import.meta.env.VITE_TARGET,

  API_TIMEOUT_DURATION: import.meta.env.VITE_API_TIMEOUT_DURATION,

  API_ENDPOINT: import.meta.env.VITE_API_ENDPOINT,
  FLOG_ENDPOINT: import.meta.env.VITE_FLOG_ENDPOINT,

  GOOGLE_ANALYTICS_UA_ID: import.meta.env.VITE_GOOGLE_ANALYTICS_UA_ID,
  GOOGLE_GTAG_ID: import.meta.env.VITE_GOOGLE_GTAG_ID,

  CRYPTO_SECRET: import.meta.env.VITE_CRYPTO_SECRET,

  SOURCE_BUILD_NUMBER: SOURCE.SOURCE_BUILD_NUMBER,
  SOURCE_BUILD_ID: SOURCE.SOURCE_BUILD_ID,
  SOURCE_VERSION: SOURCE.SOURCE_VERSION,
  SOURCE_BUILD_DATE: SOURCE.SOURCE_BUILD_DATE
};

export default ENV;
