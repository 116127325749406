import _ from "lodash";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


const breakpointNames = Object.keys(BREAKPOINTS);


const generateGridMediaStyles = (values, cb = _.noop) => {
  if (_.isNil(values)) {
    return {};
  }
  const args = _.isArray(values) ? values : [ values ];
  const [ xs, ...rest ] = args;
  const styles = (rest || [])
    .map((val, index) => {
      if (_.isNil(val)) {
        return {};
      }
      const breakpointName = HELPERS.media.largerThan[breakpointNames[index + 1]];
      return {
        [breakpointName]: cb(val)
      };
    });
  return Object.assign(
    {},
    !!xs && cb(xs),
    ...styles
  );
};



export default generateGridMediaStyles;
