
// @ts-nocheck

import React, { lazy, Suspense } from "react";



const LazyComponent = lazy(() =>
  import(/* webpackChunkName: "icons" */"./Icon")
);


const Icon = (props) =>
  <Suspense
    fallback={<i style={{ width: 12 }}>&middot;</i>}>
    <LazyComponent {...props} />
  </Suspense>;

export default Icon;

